<template>
  <div>
    <section class="container-fluid g-px-100--lg mt-5 g-pt-60">
      <h1 class="g-font-weight-500 g-mb-15">Editar perfil</h1>
      <hr class="g-my-40" style="border: 1px solid;" />
    </section>

    <section class="container-fluid g-px-100--lg mb-10">
      <div
        v-if="
          currentUserInfo.userType == allConstants.security.userTypes.person
        "
      >
        <div
          class="row"
          v-if="
            currentUpdateProfile.currentStep ==
              allConstants.updateProfile.updateClaimantProfileStep
                .collectBasicData
          "
        >
          <div class="col-sm-12 col-lg-12 g-px-10 text-center">
            <img
              class="xs-display-none img-fluid"
              src="assets/img/nav-regis-pn-1.svg"
            />
            <img
              class="text-center img-fluid xs-display-block d-lg-none md-display-none sm-display-none"
              src="assets/img/nav-regis-pn-1-sm.svg"
            />
          </div>
        </div>
        <div
          class="row"
          v-if="
            currentUpdateProfile.currentStep ==
              allConstants.updateProfile.updateClaimantProfileStep
                .collectAddressData
          "
        >
          <div class="col-sm-12 col-lg-12 g-px-10 text-center">
            <img
              class="xs-display-none img-fluid"
              src="assets/img/nav-regis-pn-2.svg"
            />
            <img
              class="text-center img-fluid xs-display-block d-lg-none md-display-none sm-display-none"
              src="assets/img/nav-regis-pn-2-sm.svg"
            />
          </div>
        </div>
      </div>
      <div
        v-if="
          currentUserInfo.userType == allConstants.security.userTypes.business
        "
      >
        <div
          class="row"
          v-if="
            currentUpdateProfile.currentStep ==
              allConstants.updateProfile.updateClaimantProfileStep
                .collectBasicData
          "
        >
          <div class="col-sm-12 col-lg-12 g-px-10 text-center">
            <img
              class="xs-display-none img-fluid"
              src="assets/img/nav-regis-pj-1.svg"
            />
            <img
              class="text-center img-fluid xs-display-block d-lg-none md-display-none sm-display-none"
              src="assets/img/nav-regis-pj-1-sm.svg"
            />
          </div>
        </div>
        <div
          class="row"
          v-if="
            currentUpdateProfile.currentStep ==
              allConstants.updateProfile.updateClaimantProfileStep
                .collectAddressData
          "
        >
          <div class="col-sm-12 col-lg-12 g-px-10 text-center">
            <img
              class="xs-display-none img-fluid"
              src="assets/img/nav-regis-pj-2.svg"
            />
            <img
              class="text-center img-fluid xs-display-block d-lg-none md-display-none sm-display-none"
              src="assets/img/nav-regis-pj-2-sm.svg"
            />
          </div>
        </div>
      </div>
    </section>

    <collectorBasicData
      :userType="currentUserInfo.userType"
      :showMainButton="true"
      :showBackButton="true"
      :disableBackButton="true"
      :askForPassword="false"
      :disableIdentificationNumber="true"
      :stopIfAccountExist="false"
      :preloadData="currentUpdateProfile.collectedBasicData"
      @enter="endCollectBasicData"
      v-if="
        currentUpdateProfile.currentStep ==
          allConstants.updateProfile.updateClaimantProfileStep.collectBasicData
      "
    />

    <collectorAddressData
      :userType="currentUserInfo.userType"
      :showMainButton="true"
      :showBackButton="true"
      :disableBackButton="false"
      :preloadData="currentUpdateProfile.collectedAddressData"
      @enter="endCollectAddressData"
      @back="currentUpdateProfileGoBackward"
      v-if="
        currentUpdateProfile.currentStep ==
          allConstants.updateProfile.updateClaimantProfileStep
            .collectAddressData
      "
    />

    <section
      class="container-fluid g-px-140--lg g-px-50--sm mb-15"
      v-if="form.processing"
    >
      <div class="row">
        <div class="col-12">
          <v-progress-linear indeterminate color="cyan"></v-progress-linear>
        </div>
      </div>
    </section>

    <v-dialog v-model="showResultUpdateProfile" persistent max-width="400">
      <v-card>
        <div class="pa-6">
          <div class="row">
            <div class="col-6"><img src="assets/img/message-3.svg" /></div>
            <div class="col-6">REGISTRO ACTUALIZADO EXITOSAMENTE</div>
          </div>
          <p class="mt-3">
            Tus datos han sido actualizados exitosamente, por favor haz click en
            Continuar para volver a tu perfil
          </p>
          <v-btn class="btn-main mt-10" block @click="goHome">Continuar</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import collectorBasicData from "@/components/PublicSite/User/collectorBasicData";
import collectorAddressData from "@/components/PublicSite/User/collectorAddressData";

import baseViewMixin from "@/mixins/baseView";
import currentUserMixin from "@/mixins/currentUser";
import currentUpdateProfileMixin from "@/mixins/currentUpdateProfile";

export default {
  mixins: [baseViewMixin, currentUserMixin, currentUpdateProfileMixin],
  components: {
    collectorBasicData,
    collectorAddressData,
  },
  data() {
    return {
      showResultUpdateProfile: false,
    };
  },
  methods: {
    async endCollectBasicData(collectedData) {
      let currentUpdateProfileInProcess = this.currentUpdateProfile;
      currentUpdateProfileInProcess.collectedBasicData = collectedData;

      await this.currentUpdateProfileCreateOrUpdate(
        currentUpdateProfileInProcess
      );
      await this.currentUpdateProfileGoForeward();
    },
    async endCollectAddressData(collectedData) {
      let currentUpdateProfileInProcess = this.currentUpdateProfile;
      currentUpdateProfileInProcess.collectedAddressData = collectedData;

      await this.currentUpdateProfileCreateOrUpdate(
        currentUpdateProfileInProcess
      );
      await this.currentUpdateProfileGoForeward();

      await this.update();
    },
    async update() {
      this.initSignalProcessing();

      await this.updateAccount();

      this.showResultUpdateProfile = true;
    },
    goHome() {
      this.gotoView("resume");
    },
  },
};
</script>

<style lang="scss" scoped></style>
