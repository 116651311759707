import accountsService from "@/services/accounts"; 

import currentUserMixin from "@/mixins/currentUser";
import allConstantsMixin from "@/mixins/allConstants";

export default {
    mixins: [currentUserMixin, allConstantsMixin],
    computed: {
        currentUpdateProfile() {
            return this.$store.getters["currentUpdateProfile/currentUpdateProfile"];
        },
    },
    methods: {
        startCurrentClaimantUpdateProfile() {
            return this.currentUpdateProfileCreateOrUpdate({
                currentStep: this.allConstants.updateProfile.updateClaimantProfileStep.collectBasicData,
                collectedBasicData: {
                    identificationNumber: this.currentUserInfo.userName,
                    name: this.currentUserInfo.name,
                    surname: this.currentUserInfo.surname,
                    email: this.currentUserInfo.email,
                    phone: this.currentUserInfo.phone,
                    cellphone: this.currentUserInfo.cellphone,
                    accountId: this.currentUserInfo.id
                },
                collectedAddressData: {
                    region: this.currentUserInfo.address.region,
                    province: this.currentUserInfo.address.city,
                    commune: this.currentUserInfo.address.commune,
                    street: this.currentUserInfo.address.streetAddress,
                    apartment: this.currentUserInfo.address.apartment,
                    building: this.currentUserInfo.address.building
                }
            });
        },
        startCurrentWfUserUpdateProfile() {
            return this.currentUpdateProfileCreateOrUpdate({
                currentStep: this.allConstants.updateProfile.updateWfUserProfileStep.default
            });
        },
        currentUpdateProfileCreateOrUpdate(claim) {
            return this.$store.dispatch("currentUpdateProfile/createOrUpdate", claim);
        },
        currentUpdateProfileClear() {
            return this.$store.dispatch("currentUpdateProfile/clear");
        },
        currentUpdateProfileGoForeward() {
            const actualUpdateProfile = this.currentUpdateProfile;
            actualUpdateProfile.currentStep++;
            return this.currentUpdateProfileCreateOrUpdate(actualUpdateProfile);
        },
        currentUpdateProfileGoBackward() {
            const actualUpdateProfile = this.currentUpdateProfile;
            actualUpdateProfile.currentStep--;
            return this.currentUpdateProfileCreateOrUpdate(actualUpdateProfile);
        },
        async updateAccount() {
            const newAccountData = {
                name: this.currentUpdateProfile.collectedBasicData.name,
                surname: this.currentUpdateProfile.collectedBasicData.surname,
                email: this.currentUpdateProfile.collectedBasicData.email,
                phone: this.currentUpdateProfile.collectedBasicData.phone.toString(),
                cellphone: this.currentUpdateProfile.collectedBasicData.cellphone.toString(),
                address: {
                    region: this.currentUpdateProfile.collectedAddressData.region,
                    city: this.currentUpdateProfile.collectedAddressData.province,
                    commune: this.currentUpdateProfile.collectedAddressData.commune,
                    streetAddress: this.currentUpdateProfile.collectedAddressData.street,
                    apartment: this.currentUpdateProfile.collectedAddressData.apartment,
                    building: this.currentUpdateProfile.collectedAddressData.building
                }            
            };

            await accountsService.update(this.currentUserInfo.id, newAccountData);

            this.$Authentication.updateCurrentUserInfo(newAccountData);
        }
    }
};